<script setup lang="ts">
import { from as observableFrom, useObservable as useObservable1 } from '@vueuse/rxjs'
import { map } from 'rxjs/operators'
import { ProducerApi } from '~/net/apis/producer_api'
import { Tab_Filter_Type } from '~/enums/tab_filter-type'

interface IVideoType {
  tabId: Tab_Filter_Type
  title: string
}
const active = ref(Tab_Filter_Type.newest)
const router = useRouter()
const producerTagId = useRouteParam<Tab_Filter_Type>('producerId') ?? Tab_Filter_Type.newest
const onClickLeft = () => history.back()
const videoTypeList: IVideoType[] = [
  {
    tabId: Tab_Filter_Type.newest,
    title: '最新作品'
  },
  {
    tabId: Tab_Filter_Type.watch,
    title: '最多观看'
  },
  {
    tabId: Tab_Filter_Type.collect,
    title: '最多收藏'
  }
]
const producer = useObservable1(
  observableFrom(ProducerApi.queryById({ id: String(producerTagId.value) })).pipe(map(({ data }) => data))
)

console.log('producer', producerTagId, producer)
</script>

<template>
  <div class="merchant_details-warpper" min-h-screen>
    <van-nav-bar left-arrow @click-left="onClickLeft" />
    <div mt--46px flex flex-col items-center justify-center>
      <ImgWrapper class="mx-auto w-full" :src="`${producer?.coverUrl}`" placeholder="./merchant_error.png" />
    </div>
    <van-tabs v-model:active="active">
      <van-tab v-for="item in videoTypeList" :key="item.tabId" :name="item.tabId" :title="item.title">
        <div class="flex-1 overflow-auto sroll-view h-68vh">
          <PullRefreshList
            wrap-class="w-full grid grid-cols-2 gap2 px-15px"
            :request-api="ProducerApi.videoQuery"
            :offset-list="16"
            head-height="32vh"
            :request-params="{
              orderKey: active,
              viewType: 0,
              pageSize: 16,
              producerTagId
            }"
          >
            <template #="{ data }">
              <VertucakCard :item="data as CommonFilmModel" />
            </template>
          </PullRefreshList>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<style scoped>
:deep(.van-tabs) {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 2px solid #645647;
  border-bottom: none;
  flex: 1;
  overflow: hidden;
  margin-top: 0;
}

.merchant_details-warpper {
  max-height: 100vh;
  overflow: hidden;
  background-image: url('~/assets/image/ranking_list/film/film_bg.png');
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #111425;
  :deep(.van-tab--active:after) {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 9px;
    margin: 0 auto;
    width: 20px;
    height: 2px;
    background: linear-gradient(90deg, #fd9403 0%, #fd5b03 100%);
  }
}
:deep(.sroll-view::-webkit-scrollbar) {
  width: 0.01rem;
}
:deep(.van-pull-refresh::-webkit-scrollbar) {
  width: 0.03rem;
}
</style>
